<template>
  <div class="level-up-reward-layer flex flex-col">
    <!-- Big Shield (Gold) -->
    <div
      v-if="rewardAmounts.gold"
      class="earning-container large mx-auto d-flex flex-col align-items-start justify-content-center"
    >
      <img :src="$whitelabel.currencies.primary.accent" style="width: 150px" />
      <div
        class="earning-btn to-center-abs gradient-indent glow text-center d-flex align-items-center justify-content-center"
      >
        <span class="golden-gradient-text">
          {{ formatNumber(rewardAmounts.gold) }}
        </span>
      </div>
    </div>

    <!-- Small Shields (Crown, Card, Loyalty) -->
    <div class="w-100 d-inline-flex align-items-center justify-content-center">
      <div
        v-for="reward in smallRewards"
        :key="reward"
        class="position-relative"
      >
        <div
          v-if="rewardAmounts[reward]"
          class="earning-container small mx-auto"
        >
          <img :src="smallEarningImg(reward)" class="to-center-abs w-75" />
          <div
            class="earning-btn to-center-abs gradient-indent glow text-center d-flex align-items-center justify-content-center"
          >
            <span class="earning-amount golden-gradient-text">
              {{ getRewardAmount(reward) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 d-inline-flex align-items-center justify-content-around">
      <!-- No Shield (Elixir, Tickets) -->
      <div
        v-if="rewardAmounts.elixir"
        class="earning-container no-shield position-relative d-flex flex-col align-items-start justify-content-center"
        style="width: 141px"
      >
        <img :src="$whitelabel.currencies.tertiary.single" />
        <div
          class="earning-btn to-center-abs gradient-indent glow text-center d-flex align-items-center justify-content-center"
        >
          <span class="earning-amount golden-gradient-text">
            {{ rewardAmounts.elixir }} x
          </span>
        </div>
      </div>
      <div
        v-if="rewardAmounts.ticket"
        class="earning-container no-shield position-relative d-flex flex-col align-items-start justify-content-center"
      >
        <img
          src="@/assets/img/animation/full-accomplishment/ticket.png"
          style="width: 100px"
        />
        <div
          class="earning-btn to-center-abs gradient-indent glow text-center d-flex align-items-center justify-content-center"
        >
          <span class="earning-amount golden-gradient-text">
            {{ rewardAmounts.elixir }} x
          </span>
        </div>
      </div>

      <!-- Slot Unlock Image -->
      <div v-if="newSlots.length > 0" class="position-relative slot-img ms-7">
        <div class="lock-img bg-img position-absolute"></div>
        <img
          v-if="newSlots.length"
          :src="newSlots[0]"
          alt="slot"
          class="w-100 h-100"
          draggable="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LevelUpRewards",
  props: {
    rewards: {
      type: Array,
      default: () => [],
    },
    newSlots: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    rewardAmounts() {
      let amounts = {
        gold: 0,
        crown: 0,
        card: 0,
        loyalty: 0,
        elixir: 0,
        booster: 0,
        ticket: 0,
      };
      this.rewards.forEach((reward) => {
        amounts[reward.reward.toLowerCase()] = reward.amount;
      });
      return amounts;
    },
    smallRewards() {
      return ["crown", "card", "loyalty", "booster"];
    },
  },

  methods: {
    smallEarningImg(type) {
      switch (type) {
        case "crown":
          return this.$whitelabel.currencies.secondary.accent;
        case "card":
          return this.$whitelabel.assets.features.cards.accent;
        case "loyalty":
          return this.$whitelabel.assets.features.loyalty.accent;
        case "booster":
          return this.$whitelabel.assets.features.booster.icon;

        default:
          return this.$whitelabel.currencies.primary.accent;
      }
    },
    getRewardAmount(type) {
      switch (type) {
        case "booster":
          return this.rewardAmounts[type] + " Min";
        default:
          return this.formatNumber(this.rewardAmounts[type]) + "x";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.level-up-reward-layer {
  position: absolute;
  top: 30px;
  width: 600px;
  left: 350px;
  height: 600px;
  font-family: Ubuntu-Bold;

  .earning-container {
    position: relative;
    background: url(~@/assets/img/common/earning-shield-bg.png) center/100% 100%
      no-repeat;
    &.large {
      width: 175px;
      height: 201px;
      margin: 0 8px 0 8px;

      .earning-btn {
        bottom: 30px;
        height: 40px;
        font-size: 36px;
        width: 120%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.small {
      width: 141px;
      height: 163px;
      .earning-btn {
        top: 96px;
        width: 122px;
        height: 36px;
        font-size: 28px;
      }
    }
    &.no-shield {
      width: 141px;
      height: 163px;
      background: none;
      .earning-btn {
        top: 96px;
        width: 122px;
        height: 36px;
        font-size: 28px;
      }
    }
  }

  .lock-img {
    left: -20px;
    width: 86px;
    height: 104px;
    background-image: url(~@/assets/img/common/lock-open.png);
    z-index: 10;
  }

  .slot-img {
    width: 137px;
    height: 88px;
    filter: drop-shadow(10px -10px 20px $btn-drop-shadow);
    border: 2px solid $bg-gradient-midnight-blue;
    object-fit: cover;
  }
}
</style>
